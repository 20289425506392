import styled from 'styled-components';
import { breakpoints } from '../../styles/variables';

const FooterStyles = styled.footer`
  ${props => props.isPromoPage ? `background: ${props.theme.colors.landDownBlue};` : props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? `background : ${props.theme.colors.vmfBlue};` : `background: ${props.theme.colors.darkBlue};`};
  color: #ffffff;
  padding: 2.6rem 0 2rem;

  @media screen and (min-width: ${breakpoints.desktop}) {
    padding: 3rem 2rem 2rem;
    ${props => props.isPromoPage || props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? `padding: 3rem ${props.theme.padding.inner} 2rem;` : ''}
  }

    .footer-row {
    display: ${props => (props.hideNavigation ? 'none' : 'flex')};
    flex-direction: row;

    @media screen and (max-width: ${breakpoints.desktop}) {
      max-width: ${props => props.theme.maxWidths.footerMenu};
      margin: auto;
      flex-direction: column;
    }

    .footer-col {
      flex: 0 0 20%;
    }
  }

  .legal-divider {
    height: 1px;
    width: 60%;
    background-color: ${props => props.theme.colors.vmfBlue};
    background-color: ${props => props.isPromoPage || props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? props.theme.colors.white : props.theme.colors.vmfBlue};
    background: ${props => props.isBusCardPage ? props.theme.colors.white : props.theme.colors.darkBlue};
    border: none;
    display: none;

    @media (max-width: ${breakpoints.desktop}) {
      display: ${props => props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? "none" : 'block'};
    }
  }
  .footer-list {
    margin: 0;
    padding: 0;

    .footer-list-item {
      list-style: none;
      margin: 0 0 0.5rem;
      line-height: 1.5em;
      padding: 0;

      a {
        color: ${props => props.theme.colors.grey5};
      }

      @media screen and (max-width: ${breakpoints.desktop}) {
        margin: 0 2rem 2rem;
      }

      &.extra-bottom {
        margin-bottom: 1.5rem;
        @media screen and (max-width: ${breakpoints.desktop}) {
          margin-bottom: 1.5rem;
        }
      }

      .top-link {
        color: ${props => props.theme.colors.white};
        font-size: 1rem;
        text-decoration: none;
        font-weight: 800;
        font-family: ${props => props.theme.fonts.secondary};
        @media screen and (max-width: ${breakpoints.desktop}) {
          padding: 1rem 2rem 1rem 0;
        }
      }

      .sub-link {
        color: #e4e5e7;
        font-size: 0.875rem;
        text-decoration: none;

        @media screen and (max-width: ${breakpoints.desktop}) {
          display: none;
        }
      }
      .button-link {
        display: inline-block;
        border: solid 1px ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.white};
        text-decoration: none;
        padding: 1rem 1.375rem;
        line-height: 1em;

        &:hover,
        &:active,
        &:focus {
          background: ${props => props.theme.colors.white};
          color: ${props => props.theme.colors.vmfBlue};
        }

        @media screen and (max-width: ${breakpoints.desktop}) {
          display: block;
          text-align: center;
          padding: 1rem;
        }
      }
    }
  }

  .divider {
    margin: 1.8rem 0 1rem;
    height: 1px;
    border: none;
    background-color: ${props => props.theme.colors.vmfBlue};
    display: ${props => (props.hideNavigation ? 'none' : 'block')};
  }

  .legal-footer {
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: ${breakpoints.desktop}) {
      flex-wrap: wrap-reverse;
      justify-content: center;
      margin: 0 1rem;
    }

    .legal-footer-text {
      font-size: 0.75rem;
      flex: 1 1 auto;
      width: 100%;

      .legal-footer-list {
        display: flex; 
        flex-direction: ${props => props.isPromoPage || props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? "column" : "row"};
        justify-items: left;
        text-align: left;
        flex-wrap: wrap;
        margin: 0;
        padding: 0;

        @media screen and (max-width: ${breakpoints.desktop}) {
          justify-content: center;
          align-items: center;
          margin-bottom: 0.35rem;
          flex-direction: column;
        }

        .legal-footer-list-item {
          list-style: none;
          margin: 0;
          padding: 0;      

          &.privacy-legal-osano {
            display: flex;
            align-items: center;
            justify-content: ${props => props.isPromoPage || props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? "left" : "center"};

            a {
              text-align: ${props => props.isPromoPage || props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? "left" : ""};
            }
            
          }

          &:first-child {
            a {
              padding-left: 0;
            }
          }

          a {
            color: #ffffff;
            text-decoration: none;
            font-size: ${props => props.theme.type.caption};
            padding: 0.375rem 0.3rem;
          }
        }

        &:first-child {
          margin-bottom: 0.25rem;

          a {
            font-weight: 700;
          }
        }

        &:nth-child(2) {
          a {
            text-decoration: underline;
          }
        }
      }

      .legal-footer-copyright {
        display: flex;
        flex-direction: column;
        align-items: center;

        .copyright-item-1 {
          flex: 0 1 auto;
        }

        .copyright-item-2 {
          flex: 2 0 auto;
        }

        @media (min-width: ${props => props.theme.breakpoints.desktop}) {
          flex-direction: row;
        }

        p {
          flex-shrink: 0;
          font-size: ${props =>
            props.hideNavigation
              ? props.theme.type.paragraph.xs
              : props.theme.type.caption};
        }

        

        .legal-footer-list {
          order: ${props => (props.hideNavigation ? -1 : 1)};

          @media (min-width: ${props => props.theme.breakpoints.desktop}) {
            order: 1;
          }
        }

        .legal-footer-list-item {
          a {
            text-decoration: none;
            padding: 0 0.875rem;
            border-left: solid 1px ${props => props.theme.colors.white};
            font-size: ${props =>
              props.hideNavigation
                ? props.theme.type.paragraph.xs
                : props.theme.type.caption};
          }

          &:first-child {
            a {
              padding-left: 0.875rem;
              margin-left: 0.875rem;

              @media (max-width: ${props => props.theme.breakpoints.desktop}) {
                border-left: none;
                margin-left: 0;
              }
            }
          }
        }

        @media screen and (max-width: ${breakpoints.desktop}) {
          text-align: center;
        }
      }
    }
    .legal-footer-icons {
      display: flex;
      flex-direction: row;
      align-items: center;
      order: ${props => (props.hideNavigation ? -1 : 1)};

      @media (min-width: ${props => props.theme.breakpoints.desktop}) {
        order: 1;
      }

      picture {
        margin: 0;
        width: 88px;
        height: 87px;
        margin-right: 24px;
      }
ttt
      
      svg {
        width: 72px;
      }
    }
  }



  .back_link {
    ${props => props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? `display: none;` : ""}
    padding-bottom: 1rem;
    padding-left: ${props => props.isEnergyRightPage || props.isPromoPage || props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? '0' : '1rem'};;
    padding-right: 1rem;
    border-bottom: solid 1px ${props => props.isPromoPage || props.isHugDiscontinuedPage || props.isTvaDiscontinuedPage ? props.theme.colors.white : props.theme.colors.vmfBlue};
    border-bottom: solid 1px ${props => props.isBusCardPage ? props.theme.colors.white : props.theme.colors.vmfBlue};
    margin-bottom: 1.75rem;
    

    button {
      border: none;
      background: transparent;
      color: ${props => props.isPromoPage ? props.theme.colors.takeFiveBlue : props.theme.colors.white};
      display: flex;
      flex-direction: row;
      align-items: center;
      font-family: ${props => props.theme.fonts.secondary};
      font-size: ${props => props.theme.type.h5};
      padding: 0;
      margin: 0;
      cursor: pointer;
      }
  
      svg {
        transform: rotate(180deg);
        height: 1em;
        margin-left: -6px;
      }
    }

  @media (max-width: ${breakpoints.desktop}) {
    .back_link {
      padding-left: 1rem;
    }

    ${
      props => props.isEnergyRightPage
        ? `
          .legal-footer
          .legal-footer-text
          .legal-footer-copyright
          .legal-footer-list {
            flex-direction: row;
          }
        ` : ''
    }
  }

  ${
    props => props.isEnergyRightPage
      ? `
        @media (min-width: ${breakpoints.desktop}) {
          padding-top: 13.5725rem;
        }
      
        @media (max-width: ${breakpoints.desktop}) {
          padding-top: 12.0538rem;
        }
      ` : ''
  }
`;

export default FooterStyles;
